<template>
  <div class="container-fluid">
    <TableWithPagination
      ref="requests"
      :items="requests"
      :fields="requestsFields"
      :totalRows="requestsTotal"
      @change-params="onRequestsChangeParams"
      @row-selected="onRequestsRowSelected"
    >
      <template v-slot:filters="{ filters, changeFilter }">
        <div class="row">
          <div class="col" v-if="isLeader">
            <b-form-select v-model="filters.regionId" :options="regionsDD" @change="changeFilter('regionId', $event)">
              <template #first>
                <b-form-select-option :value="undefined">Регион</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col">
            <b-form-input v-model="filters.search" placeholder="Поиск" type="search" @input="changeFilter('search', $event)"></b-form-input>
          </div>
        </div>
      </template>
      <template v-slot:row-actions v-if="allowManufacturerEditRequest">
        <b-button class="mr-2 btn-success" @click="onEditRequest()">Редактировать</b-button>
      </template>
    </TableWithPagination>

    <RightSidebar
      :form="requestFormName"
      :form-data="requestFormData"
      :mode="requestFormMode"
      :show="requestFormShow"
      @update:show="requestFormShow = $event;requestFormData = {};"
      @saved:form-data="onSavedRequestForm"
    ></RightSidebar>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapGetters } from "vuex";
import { RequestTypeId, RequestStatusId } from "@/constants";
import { DateFormat } from "@/helpers";
import { TableWithPagination, RightSidebar } from "@/components";

export default {
  name: "Requests",
  components: {
    TableWithPagination,
    RightSidebar,
  },
  data() {
    return {
      filters: {
        regionId: null,
        search: "",
      },
      requestsFields: [
        { key: "dateCreated", label: "Дата", sortable: true, formatter: (item) => DateFormat.getDate(item) },
        { key: "id", label: "ID", sortable: true },
        { key: "requestTypeView", label: "Тип запроса", sortable: true },
        { key: "partnerInn", label: "ИНН", sortable: true },
        { key: "partnerName", label: "Наименование", sortable: true },
        { key: "partnerAddress", label: "Адрес", sortable: true },
        { key: "initiatorName", label: "Инициатор", sortable: true },
        { key: "initiatorEmail", label: "Email", sortable: true },
        { key: "statusView", label: "Статус", sortable: true },
        { key: "lastComment", label: "Сообщение", sortable: false },
      ],
      selectedRequest: null,

      requestFormName: null,
      requestFormData: {},
      requestFormMode: "EDIT:SEND",
      requestFormShow: false,

      allowManufacturerEditRequest: false,
      isSidebarOpen: false,
    };
  },
  methods: {
    ...mapActions("regions", ["fetchRegions"]),
    ...mapActions("requests", [
      "fetchRequests",
      "fetchManufacturerRequestData",
      "editManufacturerRequestData",
      "fetchRequestSelfRetail",
      "editRequestSelfRetail",
    ]),

    onRequestsChangeParams(params) {
      this.fetchRequests(params);
    },
    onRequestsRowSelected(request) {
      this.selectedRequest = request;
      if (this.isManufacturer) this.allowManufacturerEditRequest = this.selectedRequest && this.selectedRequest.status == RequestStatusId.RejectedForCorrection;
      else if (this.selectedRequest) this.$router.push(`/requests/${this.selectedRequest.id}`);
    },

    async onEditRequest() {
      if ([RequestTypeId.AddDealer, RequestTypeId.AddDealerCertificate].includes(this.selectedRequest.requestType)) {
        await this.fetchManufacturerRequestData(this.selectedRequest.id).then(() => {
          this.requestFormName = "PartnerForm"
          this.requestFormData = {
            ...this.manufacturerRejectedRequestData,
            requestId: this.selectedRequest.id,
            spotAddressCoordinates: this.selectedRequest.partnerCoordinates,
          };
          this.requestFormShow = true
        });
      } else if ([RequestTypeId.AddSelfRetail, RequestTypeId.AddSelfRetailCertificate].includes(this.selectedRequest.requestType)) {
        await this.fetchRequestSelfRetail(this.selectedRequest.id).then(() => {
          this.requestFormName = "SelfRetailForm"
          this.requestFormData = {
            ...this.selfRetailRejectedRequestData,
            requestId: this.selectedRequest.id,
            spotAddressCoordinates: this.selectedRequest.partnerCoordinates,
          };
          this.requestFormShow = true
        });
      }
    },
    onSavedRequestForm() {
      this.$refs.requests.reload();
    }
  },
  computed: {
    ...mapGetters(["userRole", "isAdmin", "isLeader", "isManager", "isCurator", "isManufacturer"]),
    ...mapGetters("regions", ["regionsDD"]),
    ...mapState("requests", {
      requests: (state) => state.requests,
      requestsTotal: (state) => state.requestsTotal,
      manufacturerRejectedRequestData: (state) => state.manufacturerRejectedRequestData,
      selfRetailRejectedRequestData: (state) => state.requestData,
    }),
  },

  created() {
    this.fetchRegions();
  },
};
</script>

<style lang="scss"></style>
